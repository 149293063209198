import React from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby-link';
import { MDXProvider } from '@mdx-js/react';
import 'intl';

import HeaderImage from '../HeaderImage';
import ProjectDetailTitle from '../ProjectDetailTitle';
import ProjectDetailTags from '../ProjectDetailTags';
import ProjectDetailTextblock from '../ProjectDetailTextblock';
import ProjectImageRow from '../ProjectImageRow';
import ProjectImage from '../ProjectImage';
import ProjectVimeoPlayer from '../ProjectVimeoPlayer';
import ProjectDetailCaption from '../ProjectDetailCaption';

import FooterEnd from '../Footer/FooterEnd';
import Footer from '../Footer';
import Header from '../Header';


// TODO: replace with web-font-loader
import '../../../static/media/fonts/fonts.css';
import './scaffolding.less';

const bgPattern = `url(${withPrefix('./media/misc/bg-pattern.png')})`;

const Layout = ({ children, location, ...props }) => {
  const components = {
    // h1: (props) => <h1 className="title colorHighlight" {...props} />,
    // p: (props) => <p className="body" {...props} />,
    HeaderImage,
    ProjectDetailTitle,
    ProjectDetailTags,
    ProjectDetailTextblock,
    ProjectImage,
    ProjectImageRow,
    ProjectVimeoPlayer,
    ProjectDetailCaption,
  };

  return (
    <div
      id="wrapper"
      style={{
        backgroundImage: bgPattern,
        backgroundPosition: '0 0',
        backgroundAttachment: 'fixed',
        backgroundRepeat: 'repeat-x',
      }}
      className={
        true ? 'initialized' : null
      }
    >

      <div id="container">
        <Helmet
          title="Lineareal"
          meta={[
            { name: 'description', content: 'Home' },
          ]}
        />
        <Header langs={[]} currentLang="de" location={location} />


        <div id="contents" key="contents" className={`content${true ? ' active' : ''}${true ? ' change' : ''}`}>
          <MDXProvider components={components}>
            {children}
          </MDXProvider>
        </div>
        <Footer />
      </div>
      <FooterEnd />
    </div>
  );
};

export default Layout;
