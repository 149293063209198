import { changeLocale, useIntl } from 'gatsby-plugin-intl';
import React from 'react';
import languages from '../../data/languages';
import './index.less';
import LanguageFlag from './LanguageFlag';

const LanguageSelect = () => {
  const intl = useIntl();

  const newLanguage = languages.langs.filter((lang) => lang !== intl.locale)[0];
  return (
    <div className="languageswitch">
      <button type="button" onClick={() => changeLocale(newLanguage)}>
        <span className="currlang">
          <LanguageFlag
            lang={newLanguage}
          />
        </span>
      </button>
    </div>
  );
};

export default LanguageSelect;
