import React from 'react';
import { Link } from 'gatsby-plugin-intl';
import ReactTimeout from 'react-timeout';
import PropTypes from 'prop-types';
import LanguageSelect from '../LanguageSelect';
import BurgerNavi from '../BurgerNavi';
import Menu from '../Menu';

import './index.less';

// import CompanyLogo from './CompanyLogo';
// import CompanyTypo from './CompanyTypo';

import { ReactComponent as CompanyLogo } from './assets/logo-icon.svg';
import { ReactComponent as CompanyTypo } from './assets/logo-typo.svg';


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.clickFlag = this.clickFlag.bind(this);
    this.clickMenu = this.clickMenu.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.completedAni = this.completedAni.bind(this);
    this.setDelayClass = this.setDelayClass.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      langSelectOpen: false,
      menuOpen: false,
      delayClass: '',
      scrolled: false,
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {
    const supportPageOffset = window.pageXOffset !== undefined;
    const isCSS1Compat = ((document.compatMode || '') === 'CSS1Compat');
    const scroll = {
      x: supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft,
      y: supportPageOffset ? window.pageYOffset : isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop,
    };
    if (scroll.y > 60 && !this.state.scrolled) {
      this.setState({ scrolled: true });
    } else if (scroll.y < 60 && this.state.scrolled) {
      this.setState({ scrolled: false });
    }
  }


  clickFlag() {
    this.setState({ langSelectOpen: !this.state.langSelectOpen });
  }

  clickMenu() {
    if (!this.state.menuOpen) {
      this.setState({ delayClass: 'prepareAnimation' });
      this.props.setTimeout(this.toggleMenu, 100);
      return;
    }
    this.toggleMenu();
  }

  toggleMenu() {
    this.setState({ menuOpen: !this.state.menuOpen }, this.completedAni);
  }

  completedAni() {
    this.props.setTimeout(this.setDelayClass, this.state.menuOpen ? 100 : 300);
  }

  setDelayClass() {
    this.setState({ delayClass: this.state.menuOpen ? 'completeAnimation' : '' });
  }

  render() {
    return (
      <div id="header" className={`header${this.state.langSelectOpen ? ' languageOpen' : ''}${this.state.scrolled ? ' scrolled' : ''}${this.state.menuOpen ? ' menuOpen' : ''} ${this.state.delayClass}`}>
        <div id="headerInner">
          <div className="logo">
            <Link to="/">
              <span className="companyLogo">
                <CompanyLogo />
              </span>
              <span className="companyName">
                <CompanyTypo />
              </span>
            </Link>
          </div>
          <LanguageSelect langs={this.props.langs} onClick={this.clickFlag} />
          <BurgerNavi onClick={this.clickMenu} />
          <Menu currentLang={this.props.currentLang} />
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  langs: PropTypes.array,
};

export default ReactTimeout(Header);
