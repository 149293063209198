import React from 'react';

const ProjectDetailTextblock = ({
  headline, text, lang, ...props
}) => (
  <div className="textitem">
    <div className="inner">

      <h3>
        {headline[lang]}
      </h3>
      <p>
        {text[lang]}
      </p>

    </div>
  </div>
);

export default ProjectDetailTextblock;
