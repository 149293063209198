import React from 'react';
// import Link from 'gatsby-link';
import { Link, FormattedMessage, useIntl } from 'gatsby-plugin-intl';
import Obfuscate from 'react-obfuscate';
import LanguageSelect from '../LanguageSelect';
import IconsSocialMedia from './IconsSocialMedia';
import { ReactComponent as LocationPin } from './assets/location-pin.svg';
// import mailto from 'react-mailto'

import './index.less';

const Footer = () => {
  const intl = useIntl();

  return (
    <div id="footer" className="footer">
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
      <div id="footerInner">

        <div className="langselect">
          <LanguageSelect />
        </div>

        <div className="col col_1">
          <ul>

            <li>
              <Link to={`/${intl.formatMessage({ id: 'footerNavigation.home.link' })}/`}>
                <FormattedMessage id="footerNavigation.home.label" />
              </Link>
            </li>

            <li>
              <Link to={`/${intl.formatMessage({ id: 'footerNavigation.about.link' })}/`}>
                <FormattedMessage id="footerNavigation.about.label" />
              </Link>
            </li>

            <li>
              <Link to={`/${intl.formatMessage({ id: 'footerNavigation.contact.link' })}/`}>
                <FormattedMessage id="footerNavigation.contact.label" />
              </Link>
            </li>

          </ul>
        </div>

        <div className="col col_2">
          <ul>
            <li>
              <Link to={`/${intl.formatMessage({ id: 'footerNavigation.privacyPolicy.link' })}/`}>
                <FormattedMessage id="footerNavigation.privacyPolicy.label" />
              </Link>
            </li>

            <li>
              <Link to={`/${intl.formatMessage({ id: 'footerNavigation.imprint.link' })}/`}>
                <FormattedMessage id="footerNavigation.imprint.label" />
              </Link>
            </li>
          </ul>
        </div>

        <div className="col col_3">
          <div className="address">
            <div className="inner">
              <address>
                {/* hallerstraße 89 – 20149 hamburg */}
                kleiner kielort 10 | hinterhof – 20144 hamburg
                <br />
                <Obfuscate style={{ display: 'inline-block' }} email="recognize@lineareal.de" />
              </address>
            </div>
            <div className="loc">
              <a href="https://goo.gl/maps/VtgsgdcX4hF8yVVp8" target="_blank" rel="noreferrer">
                <LocationPin />
              </a>
            </div>
          </div>
        </div>
        <div className="col col_4">
          <IconsSocialMedia />
        </div>

      </div>

    </div>
  );
};

export default Footer;
