import React from 'react';
import { useIntl, FormattedHTMLMessage } from 'gatsby-plugin-intl';

const ProjectDetailTags = ({
  text, lang, ...props
}) => {
  const intl = useIntl();
  return (
    <>
      {(text[lang][0] !== "") &&
        <div className="tags">
          <ul className="tagslabel">
            <li>{intl.formatHTMLMessage({ id: 'project.tagslabel' })}</li>
          </ul>
          <ul>
            {text[lang].map((listitem, index) => (
              <li key={index}>
                {listitem}
              </li>
            ))}
          </ul>
        </div>
      }
    </>
  )
};

export default ProjectDetailTags;
