import React from 'react';
import { ReactComponent as E23Logo } from './assets/e23-logo.svg';
import './footerEnd.less';

const currentYear = new Date().getFullYear().toString();

const e23Logo = <svg height="28" width="24" viewBox="0 0 24 28" xmlns="http://www.w3.org/2000/svg"><path d="M.234 4.933H23.17v1.756H.234zm0 4.626H2.38V7.803H.234zm17.752 6.995l.641-.78 1.199-1.45 1.588-1.894 1.282-1.589v-.167H12.33v1.672h7.023c-.167.167-.474.474-.808.78l-4.208 5.1.139.335c.557-.334 1.282-.474 2.313-.474 2.564 0 4.236 1.338 4.236 3.958s-1.84 4.069-4.069 4.069c-2.062 0-3.734-.948-4.459-2.871H4.442l1.923-1.756 1.282-1.115c2.062-1.895 3.428-3.567 3.428-5.573 0-2.62-1.84-4.376-4.877-4.376-2.62 0-4.599 1.589-5.323 4.236l1.839.474c.613-1.923 1.812-2.87 3.567-2.87 1.589 0 2.787.863 2.787 2.62 0 1.588-1.115 2.87-3.01 4.542L.234 24.692v.223h11.064c1.115 1.84 3.121 2.954 5.74 2.954 3.568 0 6.132-2.396 6.132-5.824 0-3.261-2.313-5.407-5.184-5.49zM.234 3.902H.79V1.923v-.557s.167.223.307.39l.975 1.198h.084l.947-1.198c.167-.167.307-.474.307-.474v2.537h.557V0h-.083L2.073 2.146.233 0zm4.292-.084h.64l.168-.557H7.09l.222.557h.641L6.198 0zm1.03-1.031l.502-1.198c.056-.168.14-.558.14-.558s.083.335.167.558l.474 1.198zM8.512.084v3.818h1.031c1.198 0 2.007-.725 2.007-1.923S10.74.084 9.542.084zm.64 3.26V.641h.391c.975 0 1.45.557 1.45 1.338 0 .78-.475 1.365-1.45 1.365zm3.178.39h2.48v-.473h-1.923v-.78h1.589v-.502h-1.589V.557h1.84V.084h-2.397zm4.543.084h1.505c.808 0 1.198-.474 1.198-1.115 0-.473-.307-.864-.78-.947.306-.084.473-.335.473-.725 0-.557-.334-.947-1.03-.947h-1.366zm.557-2.313V.557h.725c.39 0 .557.168.557.474 0 .307-.167.474-.474.474zm0 1.84V2.061h.808c.474 0 .697.251.697.641s-.14.641-.613.641zm3.818-.725v1.114h.557V2.536L23.17 0h-.64l-.81 1.589c-.055.25-.222.64-.222.64s-.167-.306-.25-.557L20.382.084h-.64l1.42 2.536z" /></svg>;

const FooterEnd = (props) => (
  <div id="footerEnd">
    <div className="inner">
      <div className="copyright">
        ©
        {' '}
        {currentYear}
        {' '}
        copyright lineareal
      </div>
      <div className="e23logo">
        <a href="https://www.eins23.tv" target="_blank">
          <E23Logo />
        </a>
      </div>
    </div>
  </div>
);

export default FooterEnd;
