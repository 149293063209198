import React from 'react';
import Img from 'gatsby-image';

const ProjectImageRow = ({
  images, children, ...props
}) => {
  let imageElements;
  let gridRepeat;
  if (Array.isArray(children)) {
    gridRepeat = children.length;
    imageElements = children.map((child) => images.filter((image) => image.sizes.originalName === child.props.imageName)[0]);
  } else {
    gridRepeat = 1;
    imageElements = [images.filter((image) => image.sizes.originalName === children.props.imageName)[0]];
  }

  return (
    <div className="imageRow" style={{ gridTemplateColumns: `repeat(${gridRepeat}, 1fr)`, gridGap: '30px' }}>
      {imageElements.map((image, index) => <Img key={index} sizes={image.sizes} />)}
    </div>

  );
};

export default ProjectImageRow;
