import React from 'react';

const ProjectDetailCaption = ({
  text, lang, ...props
}) => (
  <div className="textitem caption">
    <div className="inner">
      <p>
        {text[lang]}
      </p>

    </div>
  </div>
);

export default ProjectDetailCaption;
