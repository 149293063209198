import React from 'react';

const ProjectVimeoPlayer = ({ vimeoId, ...props }) => (
  <div className="videoRow">
    <div className="iframecontainer">
      <iframe
        src={`https://player.vimeo.com/video/${vimeoId}?autoplay=0&title=0&byline=0&portrait=0`}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      />
    </div>
  </div>

);

export default ProjectVimeoPlayer;
