import React from 'react';

const ProjectDetailTitle = ({
  text, lang, ...props
}) => (
  <h1 className="title" {...props}>
    {text[lang]}
  </h1>

);

export default ProjectDetailTitle;

// import React from 'react';

// const ProjectDetailTitle = ({
//   lang, text, ...props
// }) => (
//   <h1 className="title colorHighlight" style={{ fontSize: '120px' }} {...props}>
//     {text[lang]}
//   </h1>

// );

// export default ProjectDetailTitle;
