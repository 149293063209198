import React from 'react';
import { Link, FormattedMessage, useIntl } from 'gatsby-plugin-intl';

// import config from '../../data/config';

import './index.less';

// const getNavi = (lang) => config.mainNav[lang].map((item) => (
//   <li className={`menu_${item.className} menu_item`} key={item.className}>
//     <Link to={`/${item.link}/`}>
//       {item.label}
//     </Link>
//   </li>
// ));

const Menu = () => {
  const intl = useIntl();

  return (
    <div className="menuContainer" id="menuContainer">
      <ul id="menu">
        <li>
          {/* <Link to="/self"> */}
          <Link to={`/${intl.formatMessage({ id: 'mainNavigation.lineareal.link' })}/`}>
            <FormattedMessage id="mainNavigation.lineareal.label" />
          </Link>
        </li>
        <li>
          {/* <Link to="/projects"> */}
          <Link to={`/${intl.formatMessage({ id: 'mainNavigation.projects.link' })}/`}>
            <FormattedMessage id="mainNavigation.projects.label" />
          </Link>
        </li>
        <li>
          {/* <Link to="/self"> */}
          <Link to={`/${intl.formatMessage({ id: 'mainNavigation.about.link' })}/`}>
            <FormattedMessage id="mainNavigation.about.label" />
          </Link>
        </li>

      </ul>
    </div>
  );
};

export default Menu;
