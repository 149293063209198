import React from 'react';
import Img from 'gatsby-image';

const HeaderImage = ({ image }) => (
  <div className="headerimage">
    <Img sizes={image} />
  </div>
);

export default HeaderImage;
