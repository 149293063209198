import React from 'react';

import { ReactComponent as RegionalwertPartner } from './assets/regionalwert-partner.svg';
import { ReactComponent as LinkedIn } from './assets/linkedin.svg';
import { ReactComponent as Vimeo } from './assets/vimeo.svg';
import { ReactComponent as Xing } from './assets/xing.svg';
import { ReactComponent as Instagram } from './assets/instagram.svg';

const IconsSocialMedia = (props) => (
  <div id="socialIcons">
    <ul>
      <li>
        <a className="partner" href="https://www.regionalwert-hamburg.de" target="_blank" rel="noreferrer">
          <RegionalwertPartner />
        </a>
      </li>

      <li>
        <a className="social" href="https://de.linkedin.com/in/axel-fahrenhorst-32b704164" target="_blank" rel="noreferrer">
          <LinkedIn />
        </a>
      </li>

      <li>
        <a className="social" href="https://vimeo.com/user54006448" target="_blank" rel="noreferrer">
          <Vimeo />
        </a>
      </li>

      <li>
        <a className="social" href="https://www.xing.com/profile/Axel_Fahrenhorst2/cv" target="_blank" rel="noreferrer">
          <Xing />
        </a>
      </li>

      <li>
        <a className="social" href="https://www.instagram.com/line_areal/" target="_blank" rel="noreferrer">
          <Instagram />
        </a>
      </li>
    </ul>
  </div>
);

export default IconsSocialMedia;
