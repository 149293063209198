import React from 'react';
import enhanceWithClickOutside from 'react-click-outside';

import './index.less';


class BurgerNavi extends React.Component {
  constructor(props) {
    super(props);
    this.clickBurger = this.clickBurger.bind(this);
    this.state = {
      menuOpen: false,
    };
  }

  clickBurger() {
    this.setState({ menuOpen: !this.state.menuOpen });
    this.props.onClick();
  }

  handleClickOutside() {
    if (this.state.menuOpen) {
      this.clickBurger();
    }
  }

  render() {
    return (
      <div id="burgernavi" className={this.state.menuOpen ? 'active' : ''} onClick={() => this.clickBurger()}>
        <div className="inner">
          <span />
          <span />
          <span />
        </div>
      </div>
    );
  }
}

export default enhanceWithClickOutside(BurgerNavi);
